import React, { useEffect, useRef, useState } from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { Layout } from 'components/Layout';
import { getPageStaticProps } from 'lib/dato-request';
import NewHero from 'components/Homepage/NewHero';
import { VideoTwoCol } from '@/components/VideoTwoCol';
import HowItWorksNew from '@/components/HowItWorks/HowItWorksNew';
import MailingList from '@/components/MailingList/MailingList';
import TestimonialGrid from '@/components/TestimonialCarousel/TestimonialGrid';
import VideoEmbeddedFlex from '@/components/Homepage/VideoEmbeddedFlex';
import { Trustbar2 } from '@/components/Trustbar2';
import SignupBlock from '@/components/SignupBlock/SignupBlock';
import CTASection from '@/components/MailingList/CTASection';
import FounderSection from '@/components/Parents/FounderSection';
import TryFree from '@/components/Homepage/TryFree';
import TestimonialListSimple from '@/components/ParentsLandingPage/TestimonialListSimple';
import ImageBarSection from '@/components/Trustbar2/ImageBarSection';
import VideoHero from '@/components/Homepage/VideoHero';
import PlatformSection from '@/components/PlatformSection/PlatformSection';
import ResultsSection from '@/components/PlatformSection/ResultsSection';
import VideoSection from '@/components/PlatformSection/VideoSection';
import InfoSection from '@/components/PlatformSection/InfoSection';
import InfoImage1 from '../components/PlatformSection/info-image1.png';
import InfoImage2 from '../components/PlatformSection/info-image2.png';
import InfoImage3 from '../components/PlatformSection/info-image3.png';
import EmailSignup from '@/components/PlatformSection/EmailSignup';
import { Heading2 } from '@/components/Views/Heading';
import background from '../public/assets/backgrounds/NewHomeBackground.png';
import CustomNavbar from '@/components/ParentsLandingPage/CustomNavbar';
import NewNavbar from '@/components/NewNavbar/NewNavbar';
import axios from 'axios';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';
import StickyFooterForm from '@/components/StickyFooterForm/StickyFooterForm';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { CheckoutResumed } from '@/analytics/events';
import { useMedia } from 'react-use';
import { Footer } from 'components/Footer/NewFooter2';
import RedirectModal from '@/components/RedirectModal/RedirectModal';


function getCookies() {
    if (typeof window === 'undefined') {
        return [];
    }
    const cookies = {};
    window.document.cookie.split(';').forEach((cookie) => {
        const parts = cookie.split('=');
        cookies[parts[0].trim()] = (parts[1] || '').trim();
    });
    return cookies;
}

function getCookie(name) {
    return getCookies()[name];
}

const NewHome = ({ data, location = 'home' }) => {
    const analytics = useAnalytics();
    const router = useRouter();
    const { page, site } = data;
    const { components, seo } = page;
    const elementRef = useRef<HTMLDivElement | null>(null);
    const hideFooter = useMedia('(max-width: 445px)', false);
    const [redirectModal, setRedirectModal] = useState(null);
    const [inactiveAccount, setInactiveAccount] = useState(false);
    const { infoItems } = components[6];
    const info1 = infoItems[0];
    const info2 = infoItems[1];
    const info3 = infoItems[2];

    // look for an existing checkout state
    const [checkoutData] = useLocalStorage('checkout-state', null);
    const [topElementIsVisible, updateElementIsVisible] = useState<any | null>(null);
    // console.debug('checkout state', checkoutData);

    useEffect(() => {
        console.log('checkout data:', checkoutData);
        if (checkoutData) {
            const { email, token, pathname } = checkoutData as any;
            if (email && token) {
                console.log('resuming checkout', checkoutData);
                const { pathname, ...query } = checkoutData as any;

                analytics.track(CheckoutResumed, checkoutData);

                // if an email and a token are present, then we can resume the checkout process
                router.push({ pathname: pathname ?? '/checkout', query }, undefined, { shallow: true, scroll: true });
            }
        }
    }, [analytics, checkoutData, router]);

    // check the cookie for a checkout token
    // useEffect(() => {
    //     const allCookies = getCookies();
    //     const checkoutToken = getCookie('muzology-checkout');
    //     if (checkoutToken) {
    //         // check if the token is valid
    //         console.log('checkoutToken', checkoutToken);
    //         axios.get(`/api/checkout/validate/?token=${checkoutToken}`)
    //             .then(response => {
    //                 console.log('response', response);
    //             })
    //             .catch(error => {
    //                 console.log('error', error);
    //             });
    //     }
    //     console.log('allCookies', allCookies);
    //     if (typeof document !== "undefined") {
    //         console.log('cookies', document.cookie);
    //     }
    // }, []);

    return (
        <Layout hideFooter={hideFooter}>
            <NextSeo title={seo?.title} description={seo?.description} />
            <Head>
                <meta name='keywords' content={page.keywords} />
            </Head>

            <VideoHero
                id='home-hero'
                heading={components[0].heading}
                subHeading={components[0].smallHeading}
                content={components[0].content}
                videoLink={components[0].videoLink}
                setRedirectModal={setRedirectModal}
                setInactiveAccount={setInactiveAccount}
                // videoLink='https://vimeo.com/574993176/b257f65b16'
                // subHeading={}
                // featuredImage={components[0].featuredImage}
            />
            <IntersectionObserverElement updateElementIsVisible={updateElementIsVisible} classNames='absolute top-[19rem]' ref={elementRef} />

            <PlatformSection
                id='home-platform-video'
                heading={components[1].heading}
                subHeading={components[1].smallHeading}
                content={components[1].content}
                videoLink={components[1].videoLink}
                contentClassName={components[1].contentClassName}
            />

            <ResultsSection
                id='home-results'
                title={components[2].heading}
                text={components[2].smallHeading}
                content={components[2].content}
                awardGallery={components[3].gallery}
                trustGallery={components[4].gallery}
                setRedirectModal={setRedirectModal}
                setInactiveAccount={setInactiveAccount}
            />

            <VideoSection
                id='home-video-math-fun'
                heading={components[7].heading}
                subHeading={components[7].smallHeading}
                text={components[7].subhead}
                videoLink={components[7].videoLink}
                showPlayButton={false}
                setRedirectModal={setRedirectModal}
                setInactiveAccount={setInactiveAccount}

            />

            <div className=''>
                <InfoSection
                    id='home-info-1'
                    title={info1?.header}
                    text={info1.subheader}
                    image={InfoImage1}
                    flipped={false}
                    itemsContainerCss='!w-full'
                    headerCss='mt-0'
                    containerCss='flex flex-col sm:flex-row items-center'
                    // imageContainerCss=' sm:mr-10 flex justify-start'
                    imageContainerCss=''
                    mobileBackground='pt-8 bg-cover bg-info-section-mobile-pink-top sm:bg-none sm:items-center'
                />

                <InfoSection
                    id='home-info-2'
                    title={info2?.header}
                    text={info2?.subheader}
                    image={InfoImage2}
                    flipped={true}
                    itemsContainerCss='!w-full'
                    // itemsContainerCss='items-start mt-14 sm:mt-0 '
                    headerCss='mt-0'
                    containerCss='flex flex-col sm:flex-row-reverse items-center sm:items-center'
                    imageContainerCss='mt-4 sm:mt-0'
                    mobileBackground='bg-cover bg-info-section-mobile-blue sm:bg-none'
                />

                <InfoSection
                    id='home-info-3'
                    title={info3?.header}
                    text={info3?.subheader}
                    image={InfoImage3}
                    flipped={false}
                    itemsContainerCss='mt-0 sm:mt-0 '
                    headerCss='mt-0'
                    containerCss='flex flex-col sm:flex-row mb-8 sm:mb-0 items-center sm:items-center'
                    imageContainerCss='sm:mr-10'
                    mobileBackground=' bg-cover bg-info-section-mobile-pink sm:bg-none'
                />
            </div>

            <VideoSection
                id='home-video-success-stories'
                // className='hidden sm:block'
                heading={components[5].heading}
                subHeading={components[5].smallHeading}
                text={components[5].subhead}
                videoLink={components[5].videoLink}
                setRedirectModal={setRedirectModal}
                setInactiveAccount={setInactiveAccount}
            />

            <div className='mb-0 xs2:mb-0 pb-0 xs2:pb-20 bg-cover bg-homepage-blue-pink'>
                <TestimonialGrid {...components[8]} newHomepage={true} />
                <div className='w-full hidden xs2:flex justify-center'>
                    <Heading2
                        className='text-center text-black  md:text-center mt-0 md:mt-10 mx-auto font-Poppins font-semibold'
                        heading='Let’s start today. Try it FREE!'
                    />
                </div>

                <div className='w-full hidden xs2:flex pt-10 default-px'>
                    <EmailSignup id='email-signup-home-footer' className='border-[#1CC8E1] text-black bg-[transparent]' showMobile={false} setRedirectModal={setRedirectModal} setInactiveAccount={setInactiveAccount}/>
                </div>
            </div>
            <div className='block xs2:hidden mb-20 -mt-10'>
                <Footer hideCTA={true}/>
            </div>
            <StickyFooterForm topElementIsVisible={topElementIsVisible} id={'mobile-sticky-email-capture'} setRedirectModal={setRedirectModal} setInactiveAccount={setInactiveAccount}/>
            {redirectModal && <RedirectModal reActivate={inactiveAccount}/>}

        </Layout>
    );
};

export async function getStaticProps({ preview = false }) {
    return getPageStaticProps('Homepage New');
}

export default NewHome;
